header.header {
  position: fixed;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  width: 100%;
  z-index: 100;
}
header.header .header-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px var(--left-right-padding);
}

.header-block>a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header.header .header-block .links {
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
}

.mobileViewLi {
  display: none !important;
}

.desktopViewLi {
  display: flex !important;
}

@media (max-width: 1023px) {
  .mobileViewLi {
    display: flex !important;
    margin-left: 20px;
  }

  .desktopViewLi {
    display: none !important;
  }
}

html.mobile header.header .header-block .links {
  padding: 0;
  gap: 20px;
}

header.header .header-block .links a {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

header.header .header-block .links a:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.50);
}

header.header svg:hover {
  cursor: pointer;
}

.desktop header.header svg path:nth-child(1),
.desktop header.header svg path:nth-child(2) {
  fill: var(--white);
}

header.header svg:hover path:nth-child(1),
header.header svg:hover path:nth-child(2) {
  fill: var(--green);
}

header.header .green-button {
  padding: 6.5px 12px 7.5px 13px;
  font-size: 13px;
  font-weight: 600;
  height: 30px;
  min-width: 135px;
  width: 135px;
}

header.header .header-block .links {
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
}

header.header .header-block .links li {
  align-self: stretch;
  align-items: center;
}

header.header .header-block .links a {
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

header.header .header-block .links a:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.50);
}

header.header .header-block .links a.selected {
  font-weight: 700;
  color: var(--green);
}

header.header .header-block .links a.selected:hover {
  cursor: default;
}
.headerDemoButton {
  background: #0B1611;
  color: var(--green);
  transition: color .3s ease-in-out;
}
.headerDemoButton:hover{
  color: var(--dark);
}
