.faqitem{
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-bottom: 1px solid rgba(199, 207, 211, 0.50);
}
.faqitem:last-of-type{
    border-bottom: none;
}
.mobile.faqitem{
    padding: 30px 20px;
}
.faqitem.expanded {

}
.faqheader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.faqheader:hover{
    cursor: pointer;
}
.faqheader p{
    color: var(--dark);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}
.mobile .faqheader p{
    font-size: 18px;
}
.faqheader:hover p{
    color: rgba(5, 5, 13, 0.70);
}
.mobile .faqheader:hover p{
    color: var(--dark);
}
.faqheader .iconContainer svg{
    transform: rotate(0deg);
    transition: transform 500ms ease;
}
.faqheader .iconContainer svg circle{
    stroke: var(--light);
    fill: var(--light);
    stroke-width: 1px;
}
.faqheader:hover .iconContainer svg circle{
    stroke:#C7CFD3;
}
.expanded .faqheader .iconContainer svg{
    transform: rotate(225deg);
}
.faqheader .iconContainer svg circle{
    transition: fill 300ms ease;
}
.expanded .faqheader .iconContainer svg circle{
    fill: var(--dark);
}
.faqheader .iconContainer svg path{
    transition: stroke 300ms ease;
}
.expanded .faqheader .iconContainer svg path{
    stroke: var(--white);
}
.expanded .faqheader:hover .iconContainer svg path{
    stroke: var(--green);
}

.faqbody{
    width: 90%;
    height: 0;
    overflow: hidden;
    text-align: left;
    transition: height 500ms ease;
}
.mobile .faqbody{
    width: 100%;
}
.mobile .faqbody > div{
    padding-top: 16px;
}
.faqbody p{
    color: rgba(5, 5, 13, 0.70);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    opacity: 0;
    transition: opacity 500ms ease;
}
.mobile .faqbody p{
    font-size: 18px;
}
.faqbody ul,
.faqbody ol{
    margin: 5px 0;
}
.mobile .faqbody ul{
    padding-left: 18px;
}
.faqbody ul li::marker{
    color: rgba(5, 5, 13, 0.70);
}
.mobile .faqbody ul li::marker{
    font-size: 12px;
}
.faqbody ol li::marker{
    color: rgba(5, 5, 13, 0.70);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
.mobile .faqbody ol{
    padding-left: 18px;
}
.mobile .faqbody ol li::marker{
    font-size: 16px;
}
.faqbody li{
    padding: 0 10px;
}
.expanded .faqbody p{
    opacity: 1;
}
